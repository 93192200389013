<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="APP审核管理"
        >
          <template slot="extra">
            <a-button class="editable-add-btn" @click="handleAdd" size="small">
              新增
            </a-button>
          </template>
        </a-page-header>
      </div>
      <a-table
        :columns="columns"
        :data-source="versionList"
        :pagination="false"
        :scroll="{ x: 500, y: table_h }"
        :row-key="(record) => record.id"
        size="middle"
        ref="tableList"
      >
      <span slot="system_name" slot-scope="system_name">
        {{system_name=="Android"?"安卓":""}}
        {{system_name=="iOS"?"苹果":""}}
        {{system_name=="All"?"全部":""}}
      </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button
            class="editable-add-btn"
            v-for="btn in btns"
            :key="btn"
            @click="handel(btn, num)"
            size="small"
            style="margin: 5px"
          >
            {{ btn }}
          </a-button>
        </span>
      </a-table>
      <!-- <div ref="pagination">
            <a-pagination
            v-if="pageshow"
            :default-current="currentPage"
            :defaultPageSize="defaultPageSize"
            :page-size-options="pageSizeOptions"
            show-size-changer
            show-quick-jumper
            :total="total_num"
            :show-total="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="onPageChange"
            >
            <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
            </template>
            </a-pagination>
        </div> -->
      <a-modal
        v-model="visible"
        :title="modelTitle"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        ok-text="确认"
        cancel-text="取消"
      >
        <a-form :form="addform" @submit="handleSubmit">
          <a-form-item label="软件版本：">
            <a-input
              v-decorator="[
                'version',
                {
                  rules: [{ required: true, message: '请输入软件版本!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="系统名称：">
            <a-radio-group
              v-decorator="[
                'system_name',
                {
                  rules: [{ required: true, message: '请选择系统名称!' }],
                },
              ]"
            >
              <a-radio value="iOS"> 苹果 </a-radio>
              <a-radio value="Android"> 安卓 </a-radio>
              <a-radio value="All"> 全部 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      columns: [
        {
          title: "软件版本",
          key: "version",
          dataIndex: "version",
          align: "center",
        },
        {
          title: "系统名称",
          key: "system_name",
          dataIndex: "system_name",
          scopedSlots: { customRender: "system_name" },
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      confirmLoading: false,
      pageshow: false,
      table_h: "",
      versionList: [],
      canUpload: "",
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight - this.$refs.header.offsetHeight - 45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //获取列表
    getSearchList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.get("/admin/softwareModeSwitch/list").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var versionList = res.data.data;
          for (var i = 0; i < versionList.length; i++) {
            versionList[i].btns = ["删除"];
          }
          that.changeTableH();
          that.versionList = versionList;
        }
      });
    },
    //新增海报
    handleAdd() {
      this.visible = true;
      this.modelTitle = "新增App审核版本";
      this.id = "";
      this.uploadBtn = true;
      this.fileList = [];
      var that = this;
      that.$nextTick(() => {
        that.addform.setFieldsValue({
          version: "",
          system_name: "",
        });
      });
    },
    //新增、编辑提交表单
    handleOk() {
      var that = this;
      that.confirmLoading = true;
      that.addform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios
            .post("/admin/softwareModeSwitch/add", data)
            .then((res) => {
              that.confirmLoading = false;
              if (that.$code(res)) {
                that.getSearchList({});
              }
              that.visible = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
    },

    // 操作（删除、编辑）
    handel(btn, num) {
      var that = this;
      var data;
      that.id = num.id;
      if (btn == "删除") {
        that.$confirm({
          title: "提示",
          content: "是否" + btn + "此审核版本",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
            data = {
              id: num.id,
            };
            that.axios
              .get("/admin/softwareModeSwitch/del", { params: data })
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success(btn + "成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      }
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body form {
  overflow: hidden;
}
</style>