var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"APP审核管理"}},[_c('template',{slot:"extra"},[_c('a-button',{staticClass:"editable-add-btn",attrs:{"size":"small"},on:{"click":_vm.handleAdd}},[_vm._v(" 新增 ")])],1)],2)],1),_c('a-table',{ref:"tableList",attrs:{"columns":_vm.columns,"data-source":_vm.versionList,"pagination":false,"scroll":{ x: 500, y: _vm.table_h },"row-key":function (record) { return record.id; },"size":"middle"},scopedSlots:_vm._u([{key:"system_name",fn:function(system_name){return _c('span',{},[_vm._v(" "+_vm._s(system_name=="Android"?"安卓":"")+" "+_vm._s(system_name=="iOS"?"苹果":"")+" "+_vm._s(system_name=="All"?"全部":"")+" ")])}},{key:"btns",fn:function(btns, num){return _c('span',{},_vm._l((btns),function(btn){return _c('a-button',{key:btn,staticClass:"editable-add-btn",staticStyle:{"margin":"5px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.handel(btn, num)}}},[_vm._v(" "+_vm._s(btn)+" ")])}),1)}}])}),_c('a-modal',{attrs:{"title":_vm.modelTitle,"confirm-loading":_vm.confirmLoading,"ok-text":"确认","cancel-text":"取消"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.addform},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"软件版本："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'version',
              {
                rules: [{ required: true, message: '请输入软件版本!' }],
              } ]),expression:"[\n              'version',\n              {\n                rules: [{ required: true, message: '请输入软件版本!' }],\n              },\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"系统名称："}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'system_name',
              {
                rules: [{ required: true, message: '请选择系统名称!' }],
              } ]),expression:"[\n              'system_name',\n              {\n                rules: [{ required: true, message: '请选择系统名称!' }],\n              },\n            ]"}]},[_c('a-radio',{attrs:{"value":"iOS"}},[_vm._v(" 苹果 ")]),_c('a-radio',{attrs:{"value":"Android"}},[_vm._v(" 安卓 ")]),_c('a-radio',{attrs:{"value":"All"}},[_vm._v(" 全部 ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }